<template>
    <div class="p-main p-main--about container">
        <div class="row">
            <section class="col-lg-10 offset-lg-1">
                <h1 class="h3 text-center my-5">
                    ルイ 演劇 コレクション について</h1>
                <p>ネット社会となり、あらゆる情報がインターネットにより享受できるようになった。演劇の世界も例外ではない。でも、紙媒体の公演チラシ、観劇の証しとなるチケットこそが、舞台と観客を結ぶ最強の情報源だと信じている。特に趣向を凝らした個性的なチケットには時代の空気とともに、観た舞台への思いがよみがえるチカラがある。そんな思いから個人的なコレクションを紹介して、紙による演劇アーカイブを構築した。</p>
                <h2 class="h5 text-center p-main__heading mb-5">ご利用にあたって</h2>
                <ul>
                    <li>人名、劇場名は、初演当時の呼称を基本として使用。なお作品名は再演以降、あるいは制作主体・演出家などにより初演と異なるものはその表記とした</li>
                    <li>主催者・劇団名は、例えば正式名称が「劇団◎◎」は、「劇団」を略するなど略記</li>
                    <li>上演年月日は、初演初日としているが、初日を確定できなかったものは、1日としている</li>
                    <li>不詳項目、訂正項目にお気づきの点があれば、下記にご連絡いただければ幸いです。</li>
                    <li>なお、本サイトはあくまでも個人的なコレクションを紹介しているものですので、資料としての閲覧以外の無断でのコピーや流用、転載はご遠慮ください。</li>
                </ul>
            </section>
        </div>
    </div>
</template>
<script>
// import axios from 'axios'
// import moment from "moment";
// import PostTitle from '../components/PostTitle'
// import Loading from '../components/Loading'


export default {
    components: {
        // PostTitle,
        // Loading,
    },
    //API格納用のプロパティ
    data() {
        return {
            // apiurl: 'https://script.google.com/macros/s/AKfycbyJFYh8hPOvHYEkkYG7Du7Q4dL10rKe1_82gQe8J6jYLpRpp1iK/exec',
            // items: null,
            // loading: true,
            // errored: false,
            // record: null,
            // query: "すべて",
            // search_column: null,
            // parPage: 20,
            // //currentPage: 1,
            // currentPage: this.currentPage = this.$route.params.page || 1,
            // thumbnailpath: null
        }
    },

}
</script>